import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby'
import { videoStreamTabs } from '/static/data/videoStreamAnalytics.static'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import * as styles from 'styles/videoStreamAnalyticsSolution.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller } from 'swiper/modules'
import I18nImage from 'components/gallery/i18nImage'
import * as zoomStyles from 'components/gallery/zoom.module.css'

import Icon from 'components/icon/index'
import 'swiper/css'
import 'swiper/css/effect-fade'
import './swiper.css'

const DisplayPanel = ({ value }) => {
  const { t } = useTranslation()
  const [option, setOption] = useState(0)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  const handlechange = (value) => {
    setOption(value)
  }

  useEffect(() => {
    setOption(0)
  }, [value])

  useEffect(() => {
    console.log(firstSwiper, secondSwiper)
  }, [firstSwiper, secondSwiper])

  return (
    <div className="flex lg:flex-row  flex-col-reverse justify-center items-center relative w-full">
      {/* left side panel */}
      <div
        className="relative flex justify-center items-center w-full sm:min-w-[377px]5
      basis-full lg:basis-1/3 bg-grey min-h-50 p-6 lg:mr-8 rounded-3xl transition-all duration-300 ease-in-out"
      >
        {/* panel background */}
        <div className="absolute w-full h-full bg-white top-0 left-0 z-[-3] rounded-3xl "></div>
        <div className="absolute w-full h-full bg-blue-300/5 top-0 left-0 z-[-2] rounded-3xl"></div>
        <div
          className="absolute w-full h-full bg-blue-50/20 top-0 left-0 z-[-1]  rounded-3xl opacity-30"
          style={{ boxShadow: '0px 0px 20px 0px #2C5E63 inset, 0px 0px 8px 0px #000 inset' }}
        ></div>
        {/* panel background */}
        <div
          className={`relative w-full h-auto flex justify-center flex-col  overflow-hidden transition-all ease-in-out`}
        >
          {/* for mobile */}
          <Swiper
            spaceBetween={30}
            modules={[Controller]}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            className="mySwiper"
            onSlideChange={(swiper) => {
              setOption(swiper.activeIndex)

              // console.log(swiper.activeIndex)
            }}
            key={`tab_${value}`}
          >
            <div className="lg:hidden">
              {videoStreamTabs[value].children.map((_, key) => (
                <SwiperSlide key={`${key}`}>
                  <div className="w-full h-full "></div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div
            className={`bg-white text-blue-200 text-base flex lg:hidden font-bold border-blue-200 border-l-[10px] cursor-pointer  h-15  items-center border-2 transition-all ease-in-out duration-200 rounded-r hover:bg-white/60 py-4 px-5`}
          >
            {videoStreamTabs[value].children[option] ? t(videoStreamTabs[value].children[option].title) : null}
          </div>
          <div className="block lg:hidden">
            {' '}
            <InfoBox value={value} option={option} show={true} />
          </div>
          {/* for mobile */}
          <TransitionGroup className="h-full hidden lg:block">
            {videoStreamTabs[value].children.map(({ title }, Upkey) => (
              <CSSTransition
                classNames={{
                  enter: styles.colEnter,
                  enterActive: styles.colEnterActive,
                  exit: styles.colExit,
                  exitActive: styles.colExitActive,
                  exitDone: styles.colExitDone
                }}
                appear
                key={`listGroup_${Upkey}`}
                timeout={200}
                unmountOnExit
              >
                <div
                  key={`${Upkey}_selectPanel`}
                  onClick={() => {
                    handlechange(Upkey)
                  }}
                  className={`${
                    Upkey === option
                      ? 'bg-white text-blue-200 font-bold border-blue-200 border-l-[10px]'
                      : 'ml-2 border-transparent'
                  } cursor-pointer  h-15 flex items-center border-2 transition-all ease-in-out duration-200 rounded-r hover:bg-white py-4 px-5`}
                >
                  {t(title)}
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>

        <div className="absolute rounded-full transition-all duration-300 ease-in-out border-2  top-[-16px] lg:top-auto lg:left-[-.8rem] items-center bg-white border-white lg:border-black flex lg:flex-col  lg:w-[30px] lg:py-3 px-3 lg:px-0 min-h-[32px]  lg:min-h-[50px]">
          {/* dots */}
          {videoStreamTabs[value].children.map((_, key) => (
            <div
              key={`${key}_dot`}
              onClick={() => {
                handlechange(key)
              }}
              className={`transition-all ease-in-out duration-200	 flex lg:my-1 mx-1 lg:mx-0 rounded-full ${
                option === key ? 'bg-blue-200  w-2.5 h-2.5' : 'bg-gray-300  w-1.5 h-1.5'
              }  `}
            ></div>
          ))}
          {/* dots */}
          <div
            className={`lg:hidden hidden cursor-pointer absolute left-[-35px] items-center justify-center rounded-full bg-white h-full  border-2 border-white ${
              option === 0 ? '' : 'xs:flex'
            }`}
            onClick={() => {
              if (firstSwiper) {
                firstSwiper.slidePrev()
              }
            }}
          >
            <Icon name="chevron_left" className="text-primary-400" />
          </div>
          <div
            className={`absolute right-[-35px] cursor-pointer  items-center justify-center rounded-full bg-white h-full lg:hidden hidden border-2 border-white ${
              option === videoStreamTabs[value].children.length - 1 ? '' : 'xs:flex'
            }`}
            onClick={() => {
              // setOption(option + 1)
              if (firstSwiper) {
                firstSwiper.slideNext()
              }
            }}
          >
            <Icon name="chevron_right" className="text-primary-400" />
          </div>
        </div>
      </div>
      {/* right side panel */}
      <div className={`basis-full lg:basis-2/3 h-full mb-8 xl:mb-0  `} key={`for_display`}>
        {/* mainImage */}
        <div className="lg:w-[450px] w-full rounded-[2rem] relative overflow-hidden">
          <Swiper
            spaceBetween={30}
            className="mySwiper"
            modules={[Controller]}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            onSlideChange={(swiper) => {
              setOption(swiper.activeIndex)
              // console.log(swiper.activeIndex)
            }}
            key={`tab_mainImage_${value}`}
          >
            {videoStreamTabs[value].children.map((_, key) => (
              <SwiperSlide key={`${key}`}>
                <div className="w-full h-full"></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <img src={videoStreamTabs[value].children[0].mainImage} alt="" className={`opacity-0 w-full `} />

          {videoStreamTabs[value].children.map(({ title, mainImage, mobileImage }, key) => (
            <CSSTransition
              in={key === option}
              timeout={300}
              key={key}
              classNames={{
                enter: styles.fadeEnter,
                enterActive: styles.fadeEnterActive,
                exit: styles.fadeExit,
                exitActive: styles.fadeExitActive
              }}
              unmountOnExit
            >
              <div>
                <img
                  src={mainImage}
                  alt={`mainImage_${title}`}
                  className={`top-0 left-0 rounded-[2rem] absolute  h-max-full ${mobileImage ? 'md:block hidden' : ''}`}
                />
                {mobileImage ? (
                  <img
                    src={mobileImage}
                    alt={`mobileImage_${title}`}
                    className={`top-0 left-0 rounded-[2rem] absolute  h-max-full ${mobileImage ? 'md:hidden' : ''}`}
                  />
                ) : null}
              </div>
            </CSSTransition>
          ))}
        </div>
        {/* infoBox */}
        <InfoBox value={value} option={option} />
      </div>
    </div>
  )
}

export default DisplayPanel

DisplayPanel.propTypes = {
  value: PropTypes.number.isRequired
}
InfoBox.propTypes = {
  value: PropTypes.number.isRequired,
  option: PropTypes.number.isRequired,
  show: PropTypes.bool
}
function InfoBox({ value, option, show }) {
  const { t, i18n } = useTranslation()
  return (
    <div
      className={`${
        show ? '' : 'hidden lg:block'
      } min-w-full bg-white border-blue-200 border-2 mt-3 md:mt-8 rounded-r border-l-8  pt-8 pb-5 px-5 `}
    >
      <div
        className={`relative w-full h-auto sm:min-h-[30px] ${
          option === 1 && value === 2 && i18n.language === 'en' ? 'min-h-[60px]' : 'min-h-[30px]'
        } `}
      >
        {videoStreamTabs[value].children.map(({ tags }, key) => (
          <CSSTransition
            in={key === option}
            timeout={300}
            key={`${key}_tagCon`}
            classNames={{
              enter: styles.fadeEnter,
              enterActive: styles.fadeEnterActive,
              exit: styles.fadeExit,
              exitActive: styles.fadeExitActive
            }}
            unmountOnExit
          >
            <div className="flex flex-wrap absolute h-auto max-w-full">
              {tags.map((tag, index) => (
                <div
                  key={`${index}_tag_`}
                  className={`font-normal rounded-full mb-1 mr-2 text-blue-200 min-w-[2.5rem] px-2 py-1 border
          border-blue-200 text-sm flex justify-center whitespace-no-wrap flex-shrink-0`}
                >
                  {t(tag)}
                </div>
              ))}
            </div>
          </CSSTransition>
        ))}
        {/* secondImage */}
        <div className="right-[-70px] absolute bottom-0 hidden lg:block ">
          <div className="relative w-[400px] ">
            {videoStreamTabs[value].children.map(({ title, secondImage }, key) => (
              <ImageComponent show={key === option} key={key} secondImage={secondImage} title={title} />
            ))}
          </div>
        </div>
      </div>
      {videoStreamTabs[value].children
        .filter((_c, inde) => inde === option)
        .map(({ content }, key) => (
          <div key={`${key}_content`} className=" font-light text-sm lg:text-base  mt-2 text-stone-600">
            {t(content)}
          </div>
        ))}
      <div className="h-[50px]  mt-3">
        <Link to="/sign-up" className="btn btn-bold btn-orange btn-ghost inline-block min-w-[180px] absolute z-[5]">
          {t('button.freeTrial.0')}
        </Link>
      </div>
    </div>
  )
}

ImageComponent.propTypes = {
  secondImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
}

function ImageComponent({ secondImage, title, show }) {
  const { t } = useTranslation()
  const [isZoomed, setIsZoomed] = useState(false)
  const handleIconClick = () => {
    setIsZoomed(!isZoomed)
  }
  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom)
  }, [])
  return (
    <div
      className={` ${show ? 'bottom-0 opacity-100' : 'bottom-[100px] opacity-0 pointer-events-none	'}
      absolute transition-all duration-500  cursor-zoom-in p-5 bg-white rounded-3xl  right-0 shadow-lg`}
    >
      <ControlledZoom classDialog={zoomStyles.imageZoom} onZoomChange={handleZoomChange} isZoomed={isZoomed}>
        <I18nImage
          src={secondImage}
          alt={t(title)}
          overlaybgcolorstart="rgba(0,0,0,0.5)"
          style={{
            maxHeight: '300px',
            height: 'auto',
            maxWidth: '100%',
            backgroundColor: isZoomed ? 'black  !important' : 'transparent !'
          }}
        />
      </ControlledZoom>

      <div
        onClick={handleIconClick}
        className="absolute right-3 bottom-3 shadow-lg bg-stone-500/80 p-[6px] flex justify-center items-center rounded-lg"
      >
        <Icon name="crop_free_outlined" className="text-white" />
      </div>
    </div>
  )
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}
